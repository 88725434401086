import React, { useEffect, useState } from "react";

import { Modal, Button, Col, Row, Form } from "react-bootstrap";
import * as yup from "yup";
import { Formik, useFormik } from "formik";

import { onlyHTMLTags } from "../../common/helpers";
import API from "../../common/API";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

//Validation
const validationSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required(),
  //  phone: yup.string().required(),
});
//Component
const AddEditUser = (props) => {
  const [state, setState] = useState({
    UI: {
      isLoading: false,
    },
  });
  useEffect(() => {
    console.log("props received on contact", props);
  }, []);

  const handleSubmit = async (obj) => {
    try {
      console.log("values to submit", obj);
      let path = "Account/Add";
      if (obj.id.length === 0) {
        obj.id = uuidv4();
        obj.created = moment().format("YYYY-MM-DDTHH:mm:ss");
      } else {
        path = "Account/Edit";
      }

      setState({ ...state, UI: { isLoading: !state.UI.isLoading } });

      let request = await API.postAction(path, obj);
      setState({ ...state, UI: { isLoading: !state.UI.isLoading } });
      if (request.status === 200) {
        props.toggle(request.data, true);
      }
    } catch (ex) {
      console.error(ex);
      setState({ ...state, UI: { isLoading: !state.UI.isLoading } });
    }
  };

  const formik = useFormik({
    initialValues: props.model
      ? JSON.parse(JSON.stringify(props.model))
      : {
          firstName: "",
          lastName: "",
          phone: "",
          password: "",
          email: "",
          id: "",
        },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Modal show={props.show} onHide={props.toggle} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Sub-cuenta</Modal.Title>
      </Modal.Header>

      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Row>
            <Col xs={6}>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
              />
              {formik.errors.firstName ? (
                <div className="invalid text-sm">{formik.errors.firstName}</div>
              ) : null}
            </Col>
            <Col xs={6}>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
              />
              {formik.errors.lastName ? (
                <div className="invalid text-sm">{formik.errors.lastName}</div>
              ) : null}
            </Col>

            <Col xs={6}>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              {formik.errors.email ? (
                <div className="invalid text-sm">{formik.errors.email}</div>
              ) : null}
            </Col>
            <Col xs={6}>
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
              />
              {formik.errors.phone ? (
                <div className="invalid text-sm">{formik.errors.phone}</div>
              ) : null}
            </Col>
            <Col xs={12}>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="text"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
              />
              {formik.errors.password ? (
                <div className="invalid text-sm">{formik.errors.password}</div>
              ) : null}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            type="submit"
            className="btn-sm"
            disabled={state.UI.isLoading}
          >
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddEditUser;
