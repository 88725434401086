import React from "react"
import ReactDOM, { Container } from "react-dom"
import { BrowserRouter as Router } from "react-router-dom"

// Modules for translation
import { I18nextProvider } from "react-i18next"
import i18next from "i18next"
import { resources } from "./common/languages"
import { createRoot } from "react-dom/client"
// core styles
import "./scss/volt.scss"

// vendor styles
import "@fortawesome/fontawesome-free/css/all.css"

//App Styles
import "./css/app.css"

import App from "./App"
import ScrollToTop from "./components/ScrollToTop"

i18next.init({
  interpolation: {
    escapeValue: false,
  },
  lng: "es_DO",
  resources: resources,
})

const container = document.getElementById("root")!

const root = createRoot(container)

root.render(
  <Router>
    <I18nextProvider i18n={i18next}>
      <ScrollToTop />
      <App />
    </I18nextProvider>
  </Router>
)
