import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Col, Row, Card, Container, Dropdown, Nav } from "react-bootstrap"
import PinComponent from "../Common/PinComponent"

import { Routes } from "../../routes"

import API from "../../common/API"
import Settings from "../../common/Settings"
import GoogleMapReact from "google-map-react"

// import { TraccarSocket } from "../../Context/TraccarSocket"

import { parsedUser } from "../../common/GetCurrentUser"
import { useParams } from "react-router"
import { userAvatar } from "../../common/helpers"
import DeviceDetails from "../Devices/DeviceDetails"
import Devices from "../Devices/Devices"

const DashboardOverview = () => {
  const { id } = useParams<{ id: string }>()
  // let socket = useContext(TraccarSocket)
  // if (socket) {
  //   socket.onmessage = (data, event) => {
  //     console.log("Enter on message socket from dashboard", data, event)
  //   }
  // }
  const history = useHistory()
  const user = parsedUser()
  const GoogleMapRef = React.createRef()
  const [pins, setPins] = useState<any>([])
  const [deviceSelected, setDeviceSelected] = useState<any>(null)
  const [zoom, setZoom] = useState(17)
  const [state, setState] = useState({
    fromInterval: false,
    center: { lat: 18.4718609, lng: -69.8923187 },
    scale: {
      sm: 1,
      md: 2.5,
      lg: 3.5,
      xl: 4.5,
      xxl: 6.5,
    },
    Devices: [],
    Pins: [],

    DeviceSelected: null,
    Events: [],
  })
  const [theMap, setTheMap] = useState<any>(null)
  const [theMapsReference, setTheMapsReference] = useState<any>(null)
  const handleApiLoaded = (map, mapsReference) => {
    setTheMap(map)
    setTheMapsReference(mapsReference)
  }

  //Device related

  const requestDevices = async () => {
    if (!user) {
      history.push(Routes.SignIn.path)
      return
    }
    let userId = user.id

    let header = {
      headers: {
        "Content-Type": "application/json",
      },
    }

    let request = await API.getAction(
      "device/positions?userid=" + userId,
      null,
      header
    )

    if (request.data.status === "ok") {
      setPins(request.data.response)
    }
  }

  //End device related
  const centerMap = () => {
    let withMarker = false
    if (theMapsReference) {
      const bounds = new theMapsReference.LatLngBounds()

      pins.forEach((marker: any) => {
        if (marker.positionInfo && marker.positionInfo.latitude) {
          // console.log("point", marker)
          withMarker = true
          bounds.extend(
            new theMapsReference.LatLng(
              marker.positionInfo.latitude,
              marker.positionInfo.longitude
            )
          )
        }
      })
      if (withMarker) {
        theMap.fitBounds(bounds)
        //theMap.setZoom(zoom);
        // console.log("bounds", bounds, zoom)
      }
    }
  }
  useEffect(() => {
    if (!state.fromInterval) {
      centerMap()
    }
  }, [pins, theMapsReference])

  useEffect(() => {
    // if (!IslogedIn()) {
    //  history.push(Routes.SignIn.path);
    // }
    requestDevices()
  }, [])

  useEffect(() => {
    let intervalId: any = null

    const onFocus = () => {
      intervalId = setInterval(() => {
        setState({ ...state, fromInterval: true })
        requestDevices()
      }, 5000)
    }
    const onBlur = () => {
      clearInterval(intervalId)
    }

    window.addEventListener("focus", onFocus)
    window.addEventListener("blur", onBlur)

    if (intervalId === null) {
      onFocus()
    }

    return () => {
      window.removeEventListener("focus", onFocus)
      window.removeEventListener("blur", onBlur)
      clearInterval(intervalId)
    }
  }, [])

  useEffect(() => {
    if (id) {
      setShowDetails(true)
      setDeviceSelected({
        id: id,
      })
    }
  }, [id])

  const onHandleZoom = obj => {
    // console.log(obj)
    setZoom(obj.zoom)
  }

  const [showDetails, setShowDetails] = useState(false)
  const toggleDetails = item => {
    if (item) {
      history.push(Routes.DevicesDetails.clean + item.id)
    } else {
      history.push(Routes.Dashboard.clean)
    }
  }
  const [showMenu, setShowMenu] = useState(true)
  return (
    <>
      {showDetails && (
        <DeviceDetails
          id={deviceSelected?.id}
          show={showDetails}
          toggle={toggleDetails}
        />
      )}

      <GoogleMapReact
        ref={GoogleMapRef}
        // onClick={this.handleClick}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        bootstrapURLKeys={{ key: Settings.GoogleMapsAPIKey }}
        style={{
          width: "100%",
          minHeight: "300px",
          height: "90vh",
          position: "relative",
        }}
        //id={this.props.Element.nombreCampo}
        center={{ lat: state.center.lat, lng: state.center.lng }}
        defaultZoom={16}
        zoom={zoom}
        onChange={e => onHandleZoom(e)}
      >
        {/* {console.log(zoom)} */}
        {pins
          .filter(x => x.positionInfo)
          .map((item: any, index: number) => {
            // console.log(item)
            return (
              <PinComponent
                model={item}
                key={index}
                lat={item.positionInfo.latitude}
                lng={item.positionInfo.longitude}
                zoom={zoom}
                title={item.name}
                scale={state.scale}
                onClick={toggleDetails}
              />
            )
          })}
      </GoogleMapReact>
    </>
  )
}

export default DashboardOverview
