function fromToRoute(mapObj, mapsObj, fromObj, toObj) {
  const directionsService = new mapsObj.DirectionsService();
  const directionsRenderer = new mapsObj.DirectionsRenderer();
  directionsRenderer.setMap(mapObj);
  const origin = { lat: fromObj.latitude, lng: fromObj.longitude };
  const destination = { lat: toObj.latitude, lng: toObj.longitude };

  directionsService.route(
    {
      origin: origin,
      destination: destination,
      travelMode: mapsObj.TravelMode.DRIVING,
    },
    (result, status) => {
      if (status === mapsObj.DirectionsStatus.OK) {
        directionsRenderer.setDirections({ routes: [] });
        directionsRenderer.setDirections(result);
      } else {
        console.error(`error fetching directions ${result}`);
      }
    }
  );
}

function center(mapObj, mapsObj, pins) {
  console.log("enter to center", mapObj, mapsObj, pins);
  let withMarker = false;
  if (mapsObj) {
    const bounds = new mapsObj.LatLngBounds();
    pins.forEach((marker) => {
      if (marker.latitude) {
        console.log("point", marker);
        withMarker = true;
        bounds.extend(new mapsObj.LatLng(marker.latitude, marker.longitude));
      }
    });
    if (withMarker) {
      mapObj.fitBounds(bounds);
    }
  }
}

const centerMap = (theMapsReference, theMap, pins, zoom) => {
  console.log("Is going to center on map", pins);
  let withMarker = false;

  if (theMapsReference) {
    const bounds = new theMapsReference.LatLngBounds();

    pins.forEach((marker) => {
      if (marker && marker.latitude) {
        console.log("point", marker);
        withMarker = true;
        bounds.extend(
          new theMapsReference.LatLng(marker.latitude, marker.longitude)
        );
      }
    });

    if (withMarker) {
      theMap.fitBounds(bounds);
      theMap.setZoom(zoom);
      console.log("bounds", bounds);
    }
  }
};

const MapHelper = {
  fromToRoute,
  center,
  centerMap,
};

export default MapHelper;
