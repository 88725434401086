import { Card, Row, Col, Tabs, Container, Modal } from "react-bootstrap"
import moment from "moment"
import React, { useEffect, useState } from "react"
import API from "../../common/API"
import Settings from "../../common/Settings"
import { Routes } from "../../routes"
import PinComponent from "../Common/PinComponent"
import GoogleMapReact from "google-map-react"
import { parsedUser } from "../../common/GetCurrentUser"
import { Tab } from "bootstrap"
import Stops from "../Reports/Stops"
import Trips from "../Reports/Trips"
import SMSSender from "./SMSSender"
import { commandsAvailable } from "../../common/helpers"
import { useParams } from "react-router-dom"
import MapHelper from "../../common/MapHelper"

//Translation
import { useTranslation } from "react-i18next"

const DeviceDetails = props => {
  const { t } = useTranslation("global")

  const [model, setModel] = useState(null)
  const user = parsedUser()
  const [pins, setPins] = useState([])

  const [showTrips, setShowTrips] = useState(false)
  const [showStop, setShowStop] = useState(false)
  const [showSMS, setShowSMS] = useState(false)
  const [SMSModel, setSMSModel] = useState(null)
  const GoogleMapRef = React.createRef()
  const [commands, setCommands] = useState([])
  const [theMap, setTheMap] = useState(null)
  const [theMapsReference, setTheMapsReference] = useState(null)

  const [state, setState] = useState({
    center: { lat: 18.4718609, lng: -69.8923187 },
    scale: {
      sm: 1,
      md: 2.5,
      lg: 3.5,
      xl: 4.5,
      xxl: 6.5,
    },
    zoom: 18,

    DeviceSelected: null,
    Events: [],
    UI: {
      showAddEditGeofence: false,
      GeofenceSelected: null,
    },
  })

  const handleApiLoaded = (map, mapsReference) => {
    setTheMap(map)
    setTheMapsReference(mapsReference)
  }

  //Device related
  const requestDevice = async () => {
    try {
      let request = await API.getAction(
        `device/details?id=${props.id}`,
        null,
        null
      )

      if (request.data.status === "ok") {
        let dev = request.data.response

        setModel(dev)
        setPins([dev.position])
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  const bindCommandsRemote = async () => {
    try {
      console.log(model)

      let request = await API.getAction(
        `CommandsAvailable/list?DeviceTypeId=${model.device.deviceTypeId}`,
        null,
        null
      )

      if (request.data.status === "ok") {
        let dev = request.data.response
        setCommands(dev)
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  const share = () => {
    if (model.latitude && model.longitude) {
      let title = t("generic.words.singular.model") + " " + props.model.name
      var GoogleMapDirectionURL = `https://www.google.com/maps/dir/?api=1&destination=${state.DeviceSelected.latitude},${state.DeviceSelected.longitude}`
      navigator.share({
        title: title,
        text: title,
        url: GoogleMapDirectionURL,
      })
    } else {
      alert("No hay suficiente información para compartir la ubicación. . .")
    }
  }

  const directions = () => {
    if (model.latitude && model.longitude) {
      let url = `https://www.google.com/maps/dir/?api=1&destination=${state.DeviceSelected.latitude},${state.DeviceSelected.longitude}`

      window.open(url, "_blank")
    } else {
      console.log(model)
      alert("No hay suficiente información para poder trazar una ruta. . .")
    }
  }

  useEffect(() => {
    console.log(model)
    if (model && model.position) {
      MapHelper.centerMap(
        theMapsReference,
        theMap,
        [model.position],
        state.zoom
      )

      console.log(model)
      console.log(pins)
    }
  }, [theMap, theMapsReference, model])

  useEffect(() => {
    if (model) {
      bindCommandsRemote()
    }
  }, [model])
  useEffect(() => {
    if (props.id) requestDevice()
  }, [props.id])

  useEffect(() => {
    let intervalId = null

    const onFocus = () => {
      console.log("CON FOCO")
      intervalId = setInterval(requestDevice, 5000)
    }

    const onBlur = () => {
      console.log("SIN FOCO")
      clearInterval(intervalId)
    }

    window.addEventListener("focus", onFocus)
    window.addEventListener("blur", onBlur)

    if (intervalId === null) {
      onFocus()
    }

    return () => {
      window.removeEventListener("focus", onFocus)
      window.removeEventListener("blur", onBlur)
      clearInterval(intervalId)
    }
  }, [])

  const toggleTrips = () => {
    setShowTrips(!showTrips)
  }

  const toggleShowStop = () => {
    setShowStop(!showStop)
  }

  const toggleSMS = obj => {
    setShowSMS(!showSMS)
    setSMSModel(obj)
  }

  const onHandleZoom = obj => {
    setState({
      ...state,
      zoom: obj.zoom,
    })
  }

  if (model === null || !model.device) {
    return <></>
  }
  return (
    <>
      {showStop ? (
        <Stops model={model} show={showStop} toggle={toggleShowStop} />
      ) : null}
      {showTrips ? (
        <Trips model={model} show={showTrips} toggle={toggleTrips} />
      ) : null}
      {showSMS ? (
        <SMSSender model={SMSModel} show={showSMS} toggle={toggleSMS} />
      ) : null}
      <Modal
        show={props.show}
        onHide={props.toggle}
        size="lg"
        className="modalRight"
      >
        <Modal.Header closeButton>
          <Modal.Title>{model.device.name}</Modal.Title>

          <small className="ms-2 text-muted">
            {" "}
            {model.serviceInfo
              ? moment(model.serviceInfo.lastUpdate).fromNow()
              : "--"}
          </small>
        </Modal.Header>
        <Modal.Body className="bg-light">
          <Container>
            <Row>
              <Col md={12}>
                <Card className="shadow-sm mb-4">
                  <Card.Body>
                    <Row>
                      <Col xs={12} md={6} className="mb-2">
                        <small className="text-muted">
                          {t("generic.words.singular.model")}:{" "}
                        </small>
                        <br />
                        <span className="black">
                          {model.device?.deviceType.name}
                        </span>
                      </Col>
                      <Col xs={12} md={6} className="mb-2">
                        <small>{t("generic.words.singular.phone")}: </small>
                        <br />
                        <span>{model.device?.phone}</span>
                      </Col>
                      <Col xs={12} md={6} className="mb-2">
                        <small>{t("generic.words.singular.imei")}: </small>
                        <br />
                        <span>{model.device?.identifier}</span>
                      </Col>

                      <Col xs={12} md={6} className="mb-2">
                        <small>{t("generic.words.singular.battery")}: </small>
                        <br />
                        {model.position &&
                        model.position.attributes.batteryLevel ? (
                          model.position.attributes.batteryLevel >= 80 ? (
                            <div className="badge bg-success badge-sm">
                              {model.position.attributes.batteryLevel}%
                            </div>
                          ) : model.position.attributes.batteryLevel >= 40 ? (
                            <div className="badge bg-warning badge-sm">
                              {model.position.attributes.batteryLevel}%
                            </div>
                          ) : (
                            <div className="badge bg-danger badge-sm">
                              {model.position.attributes.batteryLevel}%
                            </div>
                          )
                        ) : (
                          "--"
                        )}
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer className="text-center">
                    <div className="btn-group">
                      <button
                        className="btn btn-primary btn-sm "
                        onClick={() => {
                          console.log(model.device.deviceType.name)
                          directions()
                        }}
                      >
                        <i className="fas fa-directions"></i>{" "}
                        {t("generic.words.plural.indications")}
                      </button>
                      <button
                        className="btn btn-secondary btn-sm"
                        onClick={() => {
                          share()
                        }}
                      >
                        <i className="fas fa-share-square"></i>{" "}
                        {t("generic.words.singular.share")}
                      </button>
                    </div>
                  </Card.Footer>
                </Card>

                <Card className="shadow-sm mb-4">
                  <Card.Body>
                    <Tabs
                      defaultActiveKey="reports"
                      id="justify-tab-example"
                      className=" containerTab"
                      justify
                      fill
                    >
                      <Tab
                        eventKey="reports"
                        title={t("generic.words.plural.reports")}
                      >
                        <button
                          className="btn btn-block"
                          onClick={() => {
                            toggleShowStop()
                          }}
                        >
                          {t("generic.sentences.shutdown_report")}
                        </button>
                        <button
                          className="btn btn-block"
                          onClick={() => toggleTrips()}
                        >
                          {t("generic.sentences.travels_report")}
                        </button>
                        {/* <button className='btn btn-block' onClick={() => toggleShowStop()}>Reporte de Eventos</button>
                      <button className='btn btn-block' onClick={() => toggleShowStop()}>Reporte Historico</button> */}
                      </Tab>
                      <Tab eventKey="codes" title="Enviar Código">
                        {commands.map((item, index) => {
                          console.log(item)
                          return (
                            <button
                              key={index}
                              className="btn btn-block"
                              onClick={() => {
                                toggleSMS({
                                  Title: item.title,
                                  SMS: `sms://${model.device.phone};?&body=${item.command}`,
                                  Note: item.descriptionalLabel.replace(
                                    "{NUMBER}",
                                    model.device.phone
                                  ),
                                })
                              }}
                            >
                              {item.title}
                            </button>
                          )
                        })}
                      </Tab>
                    </Tabs>
                  </Card.Body>
                </Card>
                <Card className="shadow-sm mb-4">
                  <Card.Body>
                    <GoogleMapReact
                      ref={GoogleMapRef}
                      yesIWantToUseGoogleMapApiInternals={true}
                      onGoogleApiLoaded={({ map, maps }) =>
                        handleApiLoaded(map, maps)
                      }
                      bootstrapURLKeys={{ key: Settings.GoogleMapsAPIKey }}
                      style={{
                        width: "100%",
                        minHeight: "300px",
                        height: "68vh",
                        position: "relative",
                      }}
                      center={{ lat: state.center.lat, lng: state.center.lng }}
                      defaultZoom={state.zoom}
                      onChange={e => onHandleZoom(e)}
                    >
                      {pins[0] &&
                        pins.map((item, index) => {
                          console.log(item)
                          return (
                            <PinComponent
                              key={index}
                              model={item}
                              lat={item.latitude}
                              lng={item.longitude}
                              title={model.device?.name}
                              zoom={state.zoom}
                              scale={state.scale}
                            />
                          )
                        })}
                    </GoogleMapReact>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default DeviceDetails
