import React, { useState } from "react";
import { Routes } from "../../routes";
import { useTranslation } from "react-i18next";
import logo from '../../assets/img/logo190.png';
const Header = (props) => {
  const [toggle, setToggle] = useState(false);
  const { t } = useTranslation("global");
  return (
    <nav
      className="navbar navbar-expand-lg navbar-light fixed-top shadow-sm"
      id="mainNav"
    >
      <div className="container px-5">
        <a className="navbar-brand fw-bold" href="#page-top">
          <img
            src={logo}
            alt={t("webapp.name")}
            className="img-fluid"
            style={{ maxHeight: "32px", verticalAlign: "middle" }}
          />
          <span className="mt-3 ms-2">{t("webapp.name")}</span>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          onClick={() => {
            setToggle(!toggle);
          }}
        >
          <i className="fa fa-bars"></i>
        </button>
        <div
          className={"collapse navbar-collapse " + (toggle ? "show" : "")}
          id="navbarResponsive"
        >
          <button
            className={"btn  " + (!toggle ? "d-none" : "")}
            type="button"
            onClick={() => {
              setToggle(!toggle);
            }}
          >
            <i className="fa fa-times"></i>
          </button>
          <ul className="navbar-nav ms-auto me-4 my-3 my-lg-0">
            <li className="nav-item">
              <a className="nav-link me-lg-3" href="#home">
                {t("generic.home")}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link me-lg-3" href="#features">
                {t("generic.features")}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link me-lg-3" href="#about">
                {t("generic.about")}
              </a>
            </li>
         
          </ul>
          <button
            className="btn btn-primary rounded-pill px-3 mb-2 mb-lg-0"
            onClick={() => {
              //Redirige al Login
              window.location = Routes.SignIn.path;
            }}
          >
            <span className="d-flex align-items-center">
              <i className="bi-chat-text-fill me-2"></i>
              <span className="small">{t("generic.login")}</span>
            </span>
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Header;
