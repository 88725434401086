import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUnlockAlt, faUser } from "@fortawesome/free-solid-svg-icons";

import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Container,
  InputGroup,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import API from "../../common/API";
import { Routes } from "../../routes";

import ClearUser from "../../common/ClearUser";

import Sha256 from "../../common/sha256";

const Login = (props) => {
  const history = useHistory();

  const [state, setState] = useState({
    Email: "",
    Password: "",
    UI: {
      IsLoading: false,
      validated: false,
    },
  });

  useEffect(() => {
    ClearUser();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    console.log("form validity", form.checkValidity());
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      try {
        let password = Sha256.hash(state.Password);
        setState({
          ...state,
          UI: {
            ...state.UI,
            IsLoading: true,
          },
        });

        let query = {
          username: state.Email,
          password: password,
        };

        let request = await API.postAction("account/requestToken", query);

        console.log("token", request.data.response);
        if (request.data.status === "error") {
          alert(request.data.response);
          return;
        }
        if (request.data.response === null) {
          return;
        }

        localStorage.setItem("LogedUser", request.data.response);
        history.push(Routes.Dashboard.path);
      } catch (err) {
        console.log("Error", err.message);
      } finally {
        setState({
          ...state,
          UI: {
            ...state.UI,
            IsLoading: false,
          },
        });
      }
    }
  };

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <p className="text-center mb-4 mt-2 display-4">
            <b>LS Tracking</b>
          </p>
          <Row className="justify-content-center form-bg-image">
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h4 className="mb-0">Ingresar al sistema</h4>
                </div>
                <Form
                  className="mt-4 shadow-sm"
                  noValidate
                  validated={state.UI.validated}
                  onSubmit={handleSubmit}
                >
                  <Form.Group id="email" className="mb-4 ">
                    <Form.Label>Usuario</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUser} />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        required
                        type="text"
                        placeholder="Usuario"
                        value={state.Email}
                        onChange={(e) => {
                          setState({
                            ...state,
                            Email: e.target.value,
                          });
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Por favor ingrese un correo valido
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Contraseña</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type="password"
                          placeholder="Contraseña"
                          value={state.Password}
                          onChange={(e) => {
                            setState({
                              ...state,
                              Password: e.target.value,
                            });
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Contraseña requerida
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                    <div className="d-flex justify-content-between align-items-center mb-4 text-right">
                      <Card.Link
                        className="small text-end"
                        as={Link}
                        to={Routes.ForgotPassword.path}
                      >
                        Recuperar contraseña
                      </Card.Link>
                    </div>
                  </Form.Group>
                  <Button variant="primary" type="submit" className="w-100">
                    {state.UI.IsLoading ? (
                      <span
                        className="spinner-border spinner-border-sm me-4"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : null}
                    Ingresar
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default Login;
