import React, { useEffect, useState } from "react"

import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
} from "react-bootstrap"
import { Link, useParams } from "react-router-dom"
import API from "../../common/API"
import { Routes } from "../../routes"
import BgImage from "../../assets/img/illustrations/signin.svg"
import { useFormik } from "formik"
import * as yup from "yup"
import Sha256 from "../../common/sha256"

import logo from '../../assets/img/logo190.png';
//translation
import { useTranslation } from "react-i18next"

const PasswordRecovery = props => {
  const { id } = useParams()

  const { t } = useTranslation("global")

  //Validation
  const validationSchema = yup.object({
    NewPassword: yup.string().required("campo requrido"),

    ConfirmPassword: yup
      .string()
      .oneOf([yup.ref("NewPassword"), null], "Passwords must match"),
  })

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    bindData()
  }, [])

  const bindData = async props => {
    let request = await API.getAction("accounts/passwordRecovery?id=" + id)
    if (request.data.status === "ok") {
    } else {
      alert("Código no valido, será redireccionado")
      window.location = Routes.SignIn.path
    }
  }

  const handleSubmit = async (obj, event) => {
    setIsLoading(!isLoading)

    let password = Sha256.hash(formik.values.NewPassword)
    let query = {
      Id: id,
      Password: password,
    }
    let request = await API.postAction("account/PasswordRecoveryApply", query)
    setIsLoading(false)
    console.log("response from adding", request)
    window.location = Routes.SignIn.path
    if (request.status === 200) {
      //   props.toggle(request.data.response, true);
    }
  }
  const formik = useFormik({
    initialValues: {
      NewPassword: "",
      PasswordReset: props.match.params.id,
      ConfirmPassword: "",
    },
    validationSchema,
    onSubmit: handleSubmit,
  })

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
        <div className="text-center">
            <img
              src={logo}
              alt="pleelo"
              className="navbar-brand-light mb-4"
              style={{maxWidth:"200px"}}
            />
          </div>

          <Row
            className="justify-content-center form-bg-image"
            style={{ backgroundImage: `url(${BgImage})` }}
          >
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h4 className="mb-0">Re-establecer Contraseña</h4>
                </div>

                <Form onSubmit={formik.handleSubmit}>
                  <Row>
                    <Col xs={12} className="mt-2">
                      <label>Nueva contraseña</label>
                      <Form.Control
                        type="password"
                        name="NewPassword"
                        value={formik.values.NewPassword}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.NewPassword ? (
                        <div className="invalid text-sm">
                          {formik.errors.NewPassword}
                        </div>
                      ) : null}
                    </Col>
                    <Col xs={12} className="mt-2">
                      <label>Confirmar contraseña</label>
                      <Form.Control
                        type="password"
                        name="ConfirmPassword"
                        value={formik.values.ConfirmPassword}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.ConfirmPassword ? (
                        <div className="invalid text-sm">
                          {formik.errors.ConfirmPassword}
                        </div>
                      ) : null}
                    </Col>
                    <Col xs={12} className="mt-2">
                      <Button
                        variant="primary"
                        type="submit"
                        className="w-100"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <span
                            className="spinner-border spinner-border-sm mx-4"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : null}
                        Recuperar Contraseña
                      </Button>
                    </Col>
                  </Row>
                </Form>

                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    <Card.Link
                      as={Link}
                      to={Routes.SignIn.path}
                      className="fw-bold"
                    >
                      Vover al login
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  )
}

export default PasswordRecovery
